import React, { useState, useEffect, useRef } from "react";
import {isSp} from '../../../utils/isSp'

const SHOW_MORE_TEXT_WIDTH = 55;
const TAG_PADDING = 8;
const TAG_MARGIN = 12;

const measureTextWidth = (text, font = "14px Arial") => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = font;
  return context.measureText(text).width;
};

const TagsWDropdown = ({ tags, lines = 1, type, onTagClick, CustomTagComponent, tagPadding = TAG_PADDING }) => {
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [visibleTagsCount, setVisibleTagsCount] = useState(0);

  const TagComponent = CustomTagComponent || DefaultTag;

  useEffect(() => {
    const updateContainerWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.clientWidth);
      }
    };

    updateContainerWidth();
    window.addEventListener("resize", updateContainerWidth);
    return () => window.removeEventListener("resize", updateContainerWidth);
  }, []);

  useEffect(() => {
    let availableSpace = containerWidth * lines;
    let visibleCount = 0;

    const totalTagsWidth = tags.reduce((acc, tag) => acc + measureTextWidth(tag.name) + tagPadding + TAG_MARGIN, -TAG_MARGIN);

    if (totalTagsWidth > availableSpace) {
      availableSpace -= SHOW_MORE_TEXT_WIDTH;
    }

    tags.forEach((tag, index) => {
      if (index > 0) availableSpace -= TAG_MARGIN;
      availableSpace -= measureTextWidth(tag.name) + tagPadding;

      if (availableSpace > 0) visibleCount++;
    });

    setVisibleTagsCount(visibleCount);
  }, [containerWidth, tags, lines, tagPadding]);

  const remainingTags = tags.slice(visibleTagsCount);

  return (
    <div ref={containerRef} style={{ display: "flex", alignItems: "center", flexWrap: "wrap", marginTop: '25px', marginBottom: '5px' }}>
      {tags.slice(0, visibleTagsCount).map((tag, index) => (
        <TagComponent key={index} onClick={(e) => {
          e.stopPropagation();
          onTagClick(e, tag, type);
        }}>{tag.name}</TagComponent>
      ))}

      {remainingTags.length > 0 && (
        <div className="dropdown-container">
          <button style={{ color: isSp() ? '#1A1A1A' : '#DDDDDDDD'}} className="more-tags-button" onClick={(e) => {
            e.stopPropagation();
          }}>+{remainingTags.length} more</button>
          <div className="dropdown-content">
            {remainingTags.map((tag, index) => (
              <TagComponent key={index} onClick={() => onTagClick(tag, type)}>
                {tag.name}
              </TagComponent>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const DefaultTag = ({ children, onClick }) => (
  <button onClick={(e) => {
    e.stopPropagation();
    onClick(e);
  }} className="tag">
    {children}
  </button>
);


const styles =
  `.tag {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--navy-500);
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    min-width: 52px;
    width: fit-content;
    height: 29px;
    margin-right: ${TAG_MARGIN}px;
    margin-bottom: 5px;
    color: #FFFFFF
  }

  .tag:hover {
       background-color: var(--navy-400);
  }

  .dropdown-container {
    position: relative;
    display: inline-block;
  }

  .more-tags-button {
    margin-bottom: 5px;
    font-size: 13px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background: var(--project-card);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
    top: 100%;
    left: 0;
    min-width: 250px;
    z-index: 10;
    transform: translateX(-30%); 
    
  }

  .dropdown-container:hover .dropdown-content {
    display: block;
  }

  .dropdown-tag {
    display: block;
    margin-bottom: 5px;
  }

  .dropdown-tag:last-child {
    margin-bottom: 0;
  }`
  ;


// Inject styles into the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default TagsWDropdown;



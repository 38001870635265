import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { isSp } from "../utils/isSp";
import { hoverColor } from "../utils/colorUtils";
const ConfigContext = createContext();


export const ConfigProvider = ({ children }) => {
  const [spLogo, setSpLogo] = useState(null);
  const [backgroundPicture, setBackgroundPicture] = useState(null);
  const [accentColor, setAccentColor] = useState(null);
  const [description, setDescription] = useState(null);
  const [privacyPolicyDocument, setPrivacyPolicyDocument] = useState(null);
  const [termsOfServiceDocument, setTermsOfServiceDocument] = useState(null);

  const subDomain = window.location.host.split('.')[0];
  
  const path = window.location.pathname

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        if (!path.includes('not-found')) {
          const subdomainExistsResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/api/v2/public/user/subdomain/exists?currentUrl=${window.location.host + path}`
          );



          if (subdomainExistsResponse.status === 200) {
            if (subdomainExistsResponse.data && subdomainExistsResponse.data.exists && !subdomainExistsResponse.data.redirect) {
              const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/api/v2/public/platform-configs/white-label-product-config?subDomain=${subDomain}`
              );
              const data = response.data;

              if (data) {
                setSpLogo(data.logo?.link);
                setBackgroundPicture(data.backgroundPicture?.link);
                setDescription(data.description);
                setPrivacyPolicyDocument(data.privacyPolicyDocument?.link);
                setTermsOfServiceDocument(data.termsOfServiceDocument?.link);
                setAccentColor(data.accentColor);
                document.documentElement.style.setProperty("--accent-color", data.accentColor);
                document.documentElement.style.setProperty("--hover-color", hoverColor(data.accentColor));

                const applyStyles = (styles) => {
                  Object.entries(styles).forEach(([property, value]) => {
                    document.documentElement.style.setProperty(property, value);
                  });
                };

                applyStyles(
                  isSp()
                    ? {
                      "--navy-800": "#FFFFFF",
                      "--grey-300": "#1A1A1A",
                      "--grey-650": "#808080",
                      "--grey-660": data.accentColor,
                      "--grey-670": hoverColor(data.accentColor),
                      "--navy-400": hoverColor(data.accentColor),
                      "--navy-600": "#FFFFFF",
                      "--navy-650": "#FFFFFF",
                      "--border": "#B2B2B2",
                      "--blue-100": data.accentColor,
                      "--blue-300": data.accentColor,
                      "--blue-500": data.accentColor,
                      "--blue-550": hoverColor(data.accentColor),
                      "--blue-700": data.accentColor,
                      "--navy-500": data.accentColor,
                      "--description": "#4D4D4D",
                      "--border-color": "#B2B2B2",
                      "--burger-menu": "#FFFFFF",
                      "--button-color": "#FFFFFF",
                      "--project-hover": "#F2F2F2",
                      "--days-number": "#1A1A1A",
                      "--project-card": "#FFFFFF",
                      "--copy-right": "#808080"
                    }
                    : {
                      "--navy-800": "#0E181D",
                      "--grey-300": "#D3D4D9",
                      "--navy-600": "#122027",
                      "--border": "transparent",
                      "--description": "#D3D4D9",
                      "--border-color": "#5588A1",
                      "--burger-menu": "#286684",
                      "--button-color": "#D3D4D9",
                      "--project-hover": "#142730",
                      "--days-number": "#4999C0",
                      "--project-card": "#122027",
                      "--copy-right": "#286684"
                    }
                );
              }
            } else if (subdomainExistsResponse.data.redirect) {
              window.location.href = '/not-found';
            }
          }
        }



      } catch (error) {
        console.error("Error fetching config:", error.response?.status, error.message);
      }
    };

    fetchConfig();
  }, [subDomain]);


  return (
    <ConfigContext.Provider value={{ spLogo, accentColor, backgroundPicture, description, privacyPolicyDocument, termsOfServiceDocument }}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => useContext(ConfigContext);






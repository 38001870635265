import React, { useState, useEffect } from 'react';
import './AboutChallenges.css';
import '../../index.css';
import useScreenSize from '../../hooks/useScreenSize';

const AboutChallenges = () => {
    return (
        <div class="challenges-section">
            <div class="challenges-content">
                <h3 class="challenges-title">FinBursa: A New Era</h3>
                <p class="challenges-text">
                    We tackle the massive inefficiencies and information asymmetry in the cross-border private investment market while meeting the digital transformation needs of a rapidly evolving investment sector. By addressing these challenges, FinBursa has created a powerful, three-dimensional ecosystem:
                </p>
            </div>

            <div class="challenges-list">
                <div class="challenge-item">
                    <div class="challenge-number">1</div>
                    <p class="challenge-description">
                    A highly efficient digital transformation solution tailored for Financial Advisory Firms and Asset Managers.
                    </p>
                </div>
                <div class="challenge-item">
                    <div class="challenge-number">2</div>
                    <p class="challenge-description">
                    A global, cross-asset investment marketplace designed for Qualified and Accredited Investors, Capital Seekers, and Financial Advisory Firms and Asset Managers.
                    </p>
                </div>

                <div class="challenge-item">
                    <div class="challenge-number">3</div>
                    <p class="challenge-description">
                        A network of networks that fosters collaboration and innovation.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AboutChallenges;
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import TermsSection from '../components/TermsSection/TermsSection';
import { useContactForm } from '../components/ContactSupport/ContactFormContext';
import ContactSupport from '../components/ContactSupport/ContactSupport';

function TermsPage() {
    const { isContactFormOpen, type } = useContactForm();
    return (
        <div className="App">
            <Header />
            <TermsSection />
            <Footer />
            {isContactFormOpen && <ContactSupport type={type} />}
        </div>
    );
}

export default TermsPage;
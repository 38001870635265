import React, { useState, useEffect } from 'react';
import './Header.css';
import '../../index.css';
import { useNavigate } from "react-router-dom";
import classNames from 'classnames';
import { ReactComponent as Logo } from '../../assets/svg/finb-logo.svg';
import LinkedInIcon from '../../assets/linkedin';
import FacebookIcon from '../../assets/fcb';
import InstagramIcon from '../../assets/insta';
import TwitterIcon from '../../assets/twitter';
import LoginIcon from '../../assets/login-icon';
import useScreenSize from '../../hooks/useScreenSize';
import { useContactForm } from '../ContactSupport/ContactFormContext';
import { isSp } from '../../utils/isSp'
import { useConfig } from "../../config/ConfigContext";

const Header = () => {
  const [opened, setOpened] = useState(false);
  const { isMobile, isTablet } = useScreenSize();
  const { handleOpenContactForm } = useContactForm();
  const isSpCheck = isSp();

  const { accentColor } = useConfig();

  const defaultIconStyle = {
    fill: isSpCheck ? (accentColor || '#286684') : '#286684',
  };


  const handleNavigation = () => {
    window.location.href = "/roles";
  };

  const toggleMenu = () => {
    setOpened((prev) => !prev);
  };

  const handleGoToHomepage = () => {
    window.location.href = "/";
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    handleOpenContactForm();
  };

  const { spLogo, privacyPolicyDocument, termsOfServiceDocument } = useConfig();


  return (
    <>
      {isSpCheck ? (
        <>
          <div className="header-sp">
            <div className="header-content-sp">
              <div className="logo" onClick={handleGoToHomepage}>
                {spLogo ? (
                  <img src={spLogo} alt="SP Logo" className="custom-logo" />

                ) : (
                  <Logo className="logo" />
                )}
              </div>

              {!isMobile && !isTablet && (
                <div className="header-actions-sp">
                  <div className='login-sp-container'>
                    <LoginIcon />
                    <a href="/login" className="header-link-sp">Login</a>

                  </div>

                </div>
              )}

              {(isMobile || isTablet) && (
                <>
                  <div className="burger-menu-sp" onClick={toggleMenu}>
                    <div
                      className={classNames('tham', 'tham-e-squeeze', 'tham-w-6', {
                        'tham-active': opened,
                      })}
                    >
                      <div className="tham-box">
                        <div className="tham-inner" />
                        <div className="tham-inner" />
                        <div className="tham-inner" />
                      </div>
                    </div>
                  </div>

                  {opened && (
                    <div className="mobile-menu">
                      <div className="mobile-links">

                        <a href="/login" className="header-mobile-link-sp login">Login</a>

                        <div className="line"></div>
                        <div className="third-section-sp">
                          <a href={termsOfServiceDocument} className="header-mobile-link-small">Terms of Service</a>
                          <a href={privacyPolicyDocument} className="header-mobile-link-small">Privacy Policy</a>
                        </div>
                        <div className="line"></div>
                      </div>
                    </div>
                  )}
                </>
              )}

            </div>
          </div>

        </>
      ) : (
        <>
          <div className="header">
            <div className="header-content">
              <div className="logo" onClick={handleGoToHomepage}>
                <Logo className="logo" />
              </div>

              {!isMobile && !isTablet && (
                <div className="header-links">
                  <a href="/marketplace" className="header-link">Marketplace</a>
                  <a href="/strategic-partners" className="header-link">Strategic Partners</a>
                  <a href="/about" className="header-link">About</a>
                </div>
              )}
            </div>

            {!isMobile && !isTablet && (
              <div className="header-actions">
                <a href="/login" className="header-link">Login</a>
                <button className="global-button web-btn" onClick={handleNavigation}>Get Started</button>
              </div>
            )}

            {(isMobile || isTablet) && (
              <>
                <div className="burger-menu" onClick={toggleMenu}>
                  <div
                    className={classNames('tham', 'tham-e-squeeze', 'tham-w-6', {
                      'tham-active': opened,
                    })}
                  >
                    <div className="tham-box">
                      <div className="tham-inner" />
                      <div className="tham-inner" />
                      <div className="tham-inner" />
                    </div>
                  </div>
                </div>

                {opened && (
                  <div className="mobile-menu">
                    <div className="mobile-links">
                      <div className="first-section">
                        <a href="/marketplace" className="header-mobile-link">Marketplace</a>
                        <a href="/strategic-partners" className="header-mobile-link">Strategic Partners</a>
                        <a href="/about" className="header-mobile-link">About</a>
                      </div>
                      <div className="line"></div>
                      <a href="/login" className="header-mobile-link login">Login</a>
                      <button className="global-button mobile-button" onClick={handleNavigation}>Get Started</button>

                      <div className="line"></div>
                      <div className="third-section">
                        <a href="/terms" className="header-mobile-link-small">Terms of Service</a>
                        <a href="/privacy" className="header-mobile-link-small">Privacy Policy</a>
                        <a href="/cookie" className="header-mobile-link-small">Cookies</a>
                      </div>
                      <div className="line"></div>

                      <div className="socials-section">
                        <a
                          className="header-mobile-link-small"
                          href="javascript:void(0)"
                        >
                          Socials
                        </a>
                        <div className="socials-icons">
                          <a
                            href="https://www.facebook.com/people/FinBursa/61573694710495/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FacebookIcon className="social-icon" />
                          </a>
                          <a
                            href="https://www.linkedin.com/company/finbursa"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <LinkedInIcon className="social-icon" />
                          </a>
                          <a
                            href="https://www.instagram.com/finbursa/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <InstagramIcon className="social-icon" />
                          </a>
                          <a
                            href="https://x.com/finbursa"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <TwitterIcon className="social-icon" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}

    </>

  );
};

export default Header;

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import PolicySection from '../components/PolicySection/PolicySection';
import { useContactForm } from '../components/ContactSupport/ContactFormContext';
import ContactSupport from '../components/ContactSupport/ContactSupport';
function PolicyPage() {
    const { isContactFormOpen, type } = useContactForm();
    return (
        <div className="App">
            <Header />
            <PolicySection />
            <Footer />
            {isContactFormOpen && <ContactSupport type={type} />}

        </div>
    );
}

export default PolicyPage;
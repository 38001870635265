import React from 'react';

const FacebookIcon = ({ style = {}, className = "" }) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill={style.fill || "currentColor"} xmlns="http://www.w3.org/2000/svg">
            <path d="M32 1.76429V30.2286C32 31.2071 31.2071 31.9929 30.2357 31.9929H22.0786V19.6071H26.2357L26.8571 14.7786H22.0714V11.6929C22.0714 10.2929 22.4571 9.34286 24.4643 9.34286H27.0214V5.02143C26.5786 4.96429 25.0643 4.82857 23.2929 4.82857C19.6071 4.82857 17.0786 7.07857 17.0786 11.2143V14.7786H12.9071V19.6071H17.0786V32H1.76429C0.792857 32 0 31.2071 0 30.2357V1.76429C0 0.792857 0.792857 0 1.76429 0H30.2286C31.2071 0 32 0.792857 32 1.76429Z" fill={style.fill || "#286684"} />
        </svg>
    );
};

export default FacebookIcon;

import React from 'react';

const LocationIcon = ({ style = {}, className = "" }) => {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            style={style} 
        >
            <path
                d="M8 1.8335C9.5913 1.8335 11.1174 2.46564 12.2426 3.59086C13.3679 4.71607 14 6.2422 14 7.8335C14 9.88283 12.8827 11.5602 11.7053 12.7635C11.1171 13.3582 10.4753 13.8974 9.788 14.3742L9.504 14.5675L9.37067 14.6562L9.11933 14.8162L8.89533 14.9528L8.618 15.1142C8.42976 15.2216 8.21675 15.2781 8 15.2781C7.78325 15.2781 7.57024 15.2216 7.382 15.1142L7.10467 14.9528L6.758 14.7395L6.63 14.6562L6.35667 14.4742C5.61522 13.9725 4.9246 13.3996 4.29467 12.7635C3.11733 11.5595 2 9.88283 2 7.8335C2 6.2422 2.63214 4.71607 3.75736 3.59086C4.88258 2.46564 6.4087 1.8335 8 1.8335ZM8 5.8335C7.73736 5.8335 7.47728 5.88523 7.23463 5.98574C6.99198 6.08625 6.7715 6.23356 6.58579 6.41928C6.40007 6.605 6.25275 6.82548 6.15224 7.06813C6.05173 7.31078 6 7.57085 6 7.8335C6 8.09614 6.05173 8.35621 6.15224 8.59886C6.25275 8.84151 6.40007 9.06199 6.58579 9.24771C6.7715 9.43343 6.99198 9.58075 7.23463 9.68125C7.47728 9.78176 7.73736 9.8335 8 9.8335C8.53043 9.8335 9.03914 9.62278 9.41421 9.24771C9.78929 8.87264 10 8.36393 10 7.8335C10 7.30306 9.78929 6.79435 9.41421 6.41928C9.03914 6.04421 8.53043 5.8335 8 5.8335Z"
                fill={style.fill || "currentColor"} 
            />
        </svg>
    );
};

export default LocationIcon;

import React from 'react';

const ArrowButton = ({ onClick }) => {
    return (
        <button
            type="button"
            className="arrow-button focus:ring-4 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2"
            onClick={onClick}
            style={{
                backgroundColor: "#286684",
                color: "#D3D4D9",
            }}
        >
            <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
            >
                <path
                    stroke="#D3D4D9"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                />
            </svg>
        </button>
    );
};

export default ArrowButton;

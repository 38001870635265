import React from 'react';
import './MarketplaceBenefitsSection.css';
import '../../index.css';
import { ReactComponent as MarketplaceIcon1 } from '../../assets/svg/marketplace-icon1.svg';
import { ReactComponent as MarketplaceIcon2 } from '../../assets/svg/marketplace-icon2.svg';
import { ReactComponent as MarketplaceIcon3 } from '../../assets/svg/marketplace-icon3.svg';
import { ReactComponent as MarketplaceIcon4 } from '../../assets/svg/marketplace-icon4.svg';
import { ReactComponent as MarketplaceImage } from '../../assets/svg/forInvestor.svg';
import { useContactForm } from '../ContactSupport/ContactFormContext';

const MarketplaceBenefitsSection = ({ benefits, signuptext }) => {
    const { handleOpenContactForm } = useContactForm();

    return (
        <div className='marketplace-benefits-page'>
            <div className='marketplace-container'>
                <div className="marketplace-benefits-section">
                    <div className='marketplace-icon'> <MarketplaceIcon1 /></div>
                    <p className='marketplace-title'>Global Investor Network</p>
                    <p className='marketplace-text'>Access a diverse pool of qualified investors and advisors.</p>
                </div>
                <div className="marketplace-benefits-section">
                <div className='marketplace-icon'> <MarketplaceIcon2 /></div>
                    {/* <MarketplaceIcon2 /> */}
                    <p className='marketplace-title'>Showcase Opportunities</p>
                    <p className='marketplace-text'>Present your projects to attract capital effectively.</p>
                </div>
                <div className="marketplace-benefits-section">
                <div className='marketplace-icon'> <MarketplaceIcon3 /></div>
                    {/* <MarketplaceIcon3 /> */}
                    <p className='marketplace-title'>Cross-Border Connections</p>
                    <p className='marketplace-text'>Eliminate barriers to capital flow across markets.</p>
                </div>
                <div className="marketplace-benefits-section">
                <div className='marketplace-icon'> <MarketplaceIcon4 /></div>
                    {/* <MarketplaceIcon4 /> */}
                    <p className='marketplace-title'>Streamlined Fundraising</p>
                    <p className='marketplace-text'>Accelerate the process with an intuitive and efficient platform.</p>
                </div>
            </div>


            <div className='benefits-signup-section marketplace-section'>
                <div className="benefits-signup">
                    <div className="benefits-signup-title">Explore FinBursa and discover a new era of global investment opportunities</div>
                    <button className="global-button" onClick={() => handleOpenContactForm("demo")}>Book a demo</button>
                </div>
            </div>

            <div className='marketplace-about'>
                <div className="main-users-section">
                    <div className="main-users-texts">
                        <p className="main-title">Marketplace</p>
                        <p className="subtitle">FinBursa's Marketplace bridges the gap between capital seekers and global investors. Designed for efficiency and scalability, the platform enables issuers to showcase projects across industries while empowering investors to discover tailored opportunities. From startups to real estate ventures and impact investments, the Marketplace accelerates fundraising and removes traditional barriers, facilitating seamless connections across borders and asset classes.</p>

                    </div>
                    <div className="SpImage-container">
                        <MarketplaceImage />
                    </div>
                </div>
            </div>

        </div>
    );
};


export default MarketplaceBenefitsSection;
import React from 'react';

const CloseIcon = ({ style = {}, className = "" }) => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill={style.fill || "currentColor"} xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3002 0.7102C13.1134 0.522948 12.8597 0.417715 12.5952 0.417715C12.3307 0.417715 12.077 0.522948 11.8902 0.7102L7.00022 5.5902L2.11021 0.7002C1.92338 0.512948 1.66973 0.407715 1.40521 0.407715C1.1407 0.407715 0.887046 0.512948 0.700215 0.7002C0.310215 1.0902 0.310215 1.7202 0.700215 2.1102L5.59021 7.0002L0.700215 11.8902C0.310215 12.2802 0.310215 12.9102 0.700215 13.3002C1.09021 13.6902 1.72021 13.6902 2.11021 13.3002L7.00022 8.4102L11.8902 13.3002C12.2802 13.6902 12.9102 13.6902 13.3002 13.3002C13.6902 12.9102 13.6902 12.2802 13.3002 11.8902L8.41021 7.0002L13.3002 2.1102C13.6802 1.7302 13.6802 1.0902 13.3002 0.7102Z" fill={style.fill || "#286684"} />
        </svg>
    );
};

export default CloseIcon;

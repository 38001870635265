import React, { useState, useEffect } from 'react';
import './AboutMissionSection.css';
import '../../index.css';
import useScreenSize from '../../hooks/useScreenSize';


const AboutMissionSection = () => {


    return (
        <div className="about-mission-section">
            <p className="our-mission">Our mission</p>
            <div className='our-mission-section'>
                <div className='our-mission-content'>
                    <div className='our-mission-title-text'>
                        <p className='our-mission-content-title'>Disrupt</p>
                        <p className='our-mission-content-text unleash'>Current cross-border M&A and Advisory modus operandi through digital transformation and power of networks</p>
                    </div>
                    <div className='our-mission-title-text'>
                        <p className='our-mission-content-title'>Unleash</p>
                        <p className='our-mission-content-text unleash'>SMEs potential by bridging the expertise gap, providing access to global investors, promoting cross-border collaboration, and ensuring transparency</p>
                    </div>


                </div>

                <div className='our-mission-content'>
                    <div className='our-mission-title-text'>
                        <p className='our-mission-content-title'>Empower</p>
                        <p className='our-mission-content-text unleash'>Investors by providing unbiased and direct global access to opportunities information and technical expertise while reducing transaction costs</p>
                    </div>
                    <div className='our-mission-title-text'>
                        <p className='our-mission-content-title'>Democratise</p>
                        <p className='our-mission-content-text'>Investment opportunities informational flow and promoting capital allocation primarily in Emerging and Frontier Markets (south-north information flow)</p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default AboutMissionSection;
import React, { useState, useEffect } from 'react';
import './AboutUsMainCover.css';
import '../../index.css';
import useScreenSize from '../../hooks/useScreenSize';


const AboutUsMainCover = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const { isMobile, isTablet } = useScreenSize();

    const togglePopup = () => {
        setIsPopupOpen((prev) => !prev);
    };

    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('video-popup')) {
            setIsPopupOpen(false);
        }
    };

    const handleNavigation = () => {
        window.location.href = "/roles";
    };

    return (
        <div className="about-main-cover">
            <div className="cover-content">
                <p className="about-title">About</p> 
                <p className="about-text">FinBursa delivers a frictionless, innovative and effortless investment experience</p>
                {isMobile && (
                    <p className="small-title ">The premier professional investment <br /> ecosystem and marketplace.</p>
                )}
                <div className="button-section">
                    <button className="global-button btn" onClick={handleNavigation}>Join Now</button>
                    {/* <button className="watch-video-link" onClick={togglePopup}>
                        <span>Watch Video</span>
                        <svg
                            className="icon-stroke"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 8 14"
                            aria-hidden="true"
                        >
                            <path
                                d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />
                        </svg>
                    </button> */}

                </div>
            </div>
            {isPopupOpen && (
                <div className="video-popup" onClick={handleOverlayClick}>
                    <div className="popup-content">
                        <button className="close-button" onClick={togglePopup}>
                            <svg
                                className="h-8 w-8 text-red-500"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </button>
                        <div className="video-container">
                          <iframe width="1280" height="720"
                                  src="https://www.youtube.com/embed/oNT39U4hJIY"
                                  title="Explainer video for Finbursa"
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  referrerpolicy="strict-origin-when-cross-origin"
                                  allowfullscreen>
                          </iframe>
                        </div>

                    </div>
                </div>
            )}

        </div>
    );
};

export default AboutUsMainCover;

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ContactFormProvider } from './components/ContactSupport/ContactFormContext';
import { ConfigProvider } from "./config/ConfigContext";
import HomePage from './pages/HomePage';
import AboutUsPage from './pages/AboutUsPage';
import PublicSPpage from './pages/PublicSPpage';
import MarketplacePage from './pages/MarketplacePage';
import PublicSPportalPage from './pages/PublicSPportalPage'
import RolesPage from './pages/RolesPage';
import TermsPage from './pages/TermsPage';
import CookiesPage from './pages/CookiesPage';
import PolicyPage from './pages/PolicyPage';
import NotFoundPage from './pages/NotFoundPage'
function App() {

  return (
    <Router>
      <ConfigProvider>
        <ContactFormProvider>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutUsPage />} />
            <Route path="/roles" element={<RolesPage />} />
            <Route path="/strategic-partners" element={<PublicSPportalPage />} />
            <Route path="/marketplace" element={<MarketplacePage />} />
            <Route path="/roles/:role" element={<PublicSPpage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/cookie" element={<CookiesPage />} />
            <Route path="/privacy" element={<PolicyPage />} />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>

        </ContactFormProvider>

      </ConfigProvider>

    </Router>
  );
}

export default App;

import React, { useState, useEffect, useRef } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SelectedProjects.css';
import LocationIcon from '../../assets/location-icon';
import '../../index.css';
import { isSp } from '../../utils/isSp'
import { useConfig } from "../../config/ConfigContext";
import TagsWDropdown from './TagsWDropdown/TagsWDropdown'
const SelectedProjects = () => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [activeFilter, setActiveFilter] = useState("All");
  const [availableCategories, setAvailableCategories] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const sliderRef = useRef(null);

  const { accentColor } = useConfig();
  const defaultIconStyle = {
    fill: isSp() ? (accentColor || '#286684') : '#286684',
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const slidesToShow = isMobile ? 1.06 : 4;
  const totalSlides = filteredProjects.length;

  const CustomArrow = ({ direction, onClick }) => (
    <div
      className={`custom-arrow ${direction}`}
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '40px',
        height: '40px',
        backgroundColor: 'var(--blue-500)',
        borderRadius: '50%',
        color: '#fff',
        cursor: 'pointer',
        padding: "10px",
        position: 'absolute',
        fontSize: '18px',
        fontWeight: 'bold',
        zIndex: 1,
        top: '50%',
        transform: 'translateY(-50%)',
        [direction === 'left' ? 'left' : 'right']: '-18px',
      }}
    >
      {direction === 'left' ? '<' : '>'}
    </div>
  );

  const slickSettingsDesktop = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: !isMobile,
    prevArrow: currentSlide > 0 ? <CustomArrow direction="left" /> : null,
    nextArrow: currentSlide + slidesToShow < totalSlides ? <CustomArrow direction="right" /> : null,
    beforeChange: (_, next) => setCurrentSlide(next),
  };

  const slickSettingsMobile = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.06,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: "40px",
    arrows: false,
  };

  const slickSettings = isMobile ? slickSettingsMobile : slickSettingsDesktop;

  const categoryOrder = [
    "Debt",
    "Equity - private company",
    "Equity - venture capital",
    "Managed funds",
    "Real estate",
    "Structured products",
    "Others"
  ];

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/project/api/v2/projects/featured`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({}),
        });

        if (response.ok) {
          const data = await response.json();
          setProjects(data.content || []);
          setFilteredProjects(data.content || []);

          const categories = Array.from(new Set(data.content.map((proj) => proj.category.name)));
          setAvailableCategories(["All", ...categories]);
        } else {
          console.error("Failed to fetch projects:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  const handleFilter = (category) => {
    setActiveFilter(category);
    if (category === "All") {
      setFilteredProjects(projects);

      setCurrentSlide(0);
      sliderRef.current.slickGoTo(0);
    } else {
      setFilteredProjects(projects.filter((proj) => proj.category.name === category));
    }
  };

  return (
    <>
      {filteredProjects.length > 0 && (
        <div className="selected-projects-section">
          <div className="selected-projects-content">
            <p className="marketplace">Marketplace</p>
            <p className="selected-projects-title">Selected Projects</p>

            <div className="filters">
              {availableCategories.map((category) => (
                <button
                  key={category}
                  className={`global-button filter-button ${activeFilter === category ? "active" : ""}`}
                  onClick={() => handleFilter(category)}
                >
                  {category}
                </button>
              ))}
            </div>

            <Slider ref={sliderRef} {...slickSettings} className={`projects-carousel ${isMobile ? 'mobile-project-carousel' : 'project-carousel'}`}>
              {filteredProjects.map((project, index) => {
                const allButtons = [
                  { label: project.category.name, type: 'category' },
                  ...project.category.tags.map((tag) => ({ label: tag.name, type: 'tag' })),
                  ...project.regions.map((region) => ({ label: region.name, type: 'region' })),
                  { label: `Deal value: ${project.dealValue.name}`, type: 'dealValue' }
                ];

                const visibleButtons = allButtons.slice(0, 5);
                const remainingButtons = allButtons.slice(5);

                return (
                  <div key={index} className="project-card" onClick={() => (window.location.href = '/login')}>
                    <img src={project.picture.link} alt={project.title} className="project-image" />
                    <div className="project-info">
                      <h3 className="project-title">{project.title}</h3>
                      <p className="project-description" title={project.shortDescription}>{project.shortDescription}</p>

                      <TagsWDropdown
                        tags={[
                          { name: project.category.name, type: 'category' },
                          ...project.category.tags,
                          ...project.regions,
                        ]}
                        onTagClick={(e, tag, type) => {
                          console.log("Tag clicked:", tag, type);
                        }}
                      />

                      <div className='global-button detail-button' style={{ marginBottom: '10px' }}>
                        Deal value: {project.dealValue.name}
                      </div>

                      <hr className='border' />
                      <div className="total-size">
                        <p className="total-size-title">Total Size</p>
                        <p className="target">
                          {project.target.toLocaleString()} {project.currency}
                        </p>
                      </div>
                      <hr className='border' />

                      <div className="total-target">
                        <p className="total-size-title">Target amount</p>
                        <p className="target">
                          {project.targetAmount.toLocaleString()} {project.currency}
                        </p>
                      </div>
                      <hr className="border" />

                      <div className="project-funded">
                        <div className="days-left">
                          <p>
                            <span className="days-number">
                              {Math.max(
                                0,
                                Math.ceil(
                                  (new Date(project.endDate) - new Date()) / (1000 * 60 * 60 * 24)
                                )
                              )}{" "}
                              Days
                            </span>{" "}
                            <span className="days-text">left</span>
                          </p>
                        </div>
                      </div>

                      <hr className='border' />
                      <div className="issuer-info">
                        <div className="issuer-name-circle">
                          {project.creatorName
                            .split(" ")
                            .slice(0, 2)
                            .map((name) => name.charAt(0).toUpperCase())
                            .join("")}
                        </div>
                        <div className="issuer-name-type">
                          <div className="issuer-type">
                            {project.type.charAt(0).toUpperCase() + project.type.slice(1).toLowerCase()}
                          </div>
                          <div className="issuer-name">{project.creatorName}</div>
                        </div>
                      </div>
                      <hr className='border' />
                      <div className='project-region'>
                        <LocationIcon style={defaultIconStyle} />
                        <p className="region">
                          {project.regions[0].name}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      )}
    </>

  );
};

export default SelectedProjects;

import React, { useState, useEffect } from 'react';
import '../../index.css';
import './CookiesSection.css'
import useScreenSize from '../../hooks/useScreenSize';

const CookiesSection = () => {
    const { isMobile, isTablet } = useScreenSize();

    return (
        <div className="cookies-section">
            <p className='cookies-title'>COOKIE POLICY</p>
            <div className='cookies-text'>
                <React.Fragment>
                    <div>
                        This site doesn't use cookies
                    </div>
                </React.Fragment>
            </div>
        </div>
    );
};

export default CookiesSection;


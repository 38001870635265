import React, { useState, useEffect } from 'react';
import '../../index.css';
import './PolicySection.css'
import useScreenSize from '../../hooks/useScreenSize';

const PolicySection = () => {
    const { isMobile, isTablet } = useScreenSize();

    return (
        <div className="terms-section">
            <p className='terms-title'>FINBURSA PRIVACY POLICY</p>
            <div className='terms-text'>
                <React.Fragment>
                    <div className="body">
                        
                        <h2><span class="underline">INTRODUCTION</span></h2>
                        <p>(a) Welcome to FinBursa's privacy policy ("<strong>Privacy Policy</strong>"). FinBursa Technologies Ltd, a private company limited by shares incorporated in the Dubai International Financial Centre ("<strong>DIFC</strong>") in Dubai, the United Arab Emirates (the "<strong>UAE</strong>"), having registered number 8902 and having its registered address at Unit IH-00-01-02-OF-01, Level 2, Innovation Hub, DIFC, Dubai, UAE (referred to as "<strong>FinBursa</strong>", the "<strong>Company</strong>", "<strong>we</strong>", "<strong>us</strong>" or "<strong>our</strong>" in this Privacy Policy), respects your privacy and is committed to protecting your personal data. This privacy policy will inform you as to how we look after your personal data when you use the FinBursa website, application and online software (together the "<strong>Platform</strong>"), regardless of where you access it from, and tell you about your privacy rights and how the law protects you.</p>
                        <blockquote>
                            <p>By actively ticking the acceptance box and therefore accepting the terms of this Privacy Policy, you agree that you, and each person you allow to access the Platform through your user account ("<strong>User Account</strong>"), are subject to, are legally bound by, and have provided explicit consent that you will abide by the terms of this Privacy Policy.</p>
                        </blockquote>

                        <h2>1. <span class="underline">IMPORTANT INFORMATION AND WHO WE ARE</span></h2>
                        <h3>Purpose of the Privacy Policy</h3>
                        <p>(a) This Privacy Policy aims to give you information on how FinBursa collects and processes your personal data through your use of this Platform, including any data you may provide through this Platform when you use the Platform.</p>
                        <p>(b) The Platform is not intended for children and we do not knowingly collect data relating to children or persons under the age of 18 years. If you are a parent or guardian and you are aware that your child has provided us with personal data, please contact us immediately.</p>
                        <p>(c) It is important that you read this Privacy Policy together with any update that we provide to this Privacy Policy so that you are fully aware of how and why we are using your data (the current version of the Privacy Policy can be found at: <a href="https://finbursa.com/privacy">https://finbursa.com/privacy</a>). We may amend, replace, supersede, augment or otherwise update this Privacy Policy at any time in our sole discretion. Such amendment or other update will be effective either upon you actively indicating your acceptance of the updated Privacy Policy, or your continued use of the Platform following our notification to you by email or otherwise of the updated Privacy Policy will constitute your assent and acceptance of the terms of such policy. Your failure to agree to the updated Privacy Policy will constitute a rejection of its terms and we will consider this a request to terminate your User Account in accordance with the Platform Terms of Service.</p>

                        <h3>Controller</h3>
                        <p>(b) FinBursa is the controller and responsible for your personal data. FinBursa will process your personal data fairly, lawfully and securely.</p>
                        <p>(c) We have appointed a data protection officer, who is responsible for FinBursa's data protection obligations. If you have any questions about this Privacy Policy and privacy practices including any requests to exercise your legal rights, please contact the data protection officer using the details set out below:</p>
                        <ul>
                            <li>(i) Full name of legal entity: FinBursa Technologies Limited</li>
                            <li>(ii) Email address: <a href="mailto:dataprivacy@finbursa.com">dataprivacy@finbursa.com</a></li>
                            <li>(iii) Postal address: Unit IH-00-01-02-OF-01, Level 2, Innovation Hub, [PO Box [•]], DIFC, Dubai, UAE</li>
                        </ul>
                        <p>(d) You have the right to make a complaint at any time to the relevant supervisory authority for data protection issues, the details of which are as follows:</p>
                        <blockquote>
                            <p><strong>The Commissioner of Data Protection ("Commissioner")</strong></p>
                            <p>Dubai International Financial Centre Authority</p>
                            <p>Level 14, The Gate</p>
                            <p>P.O. Box 74777</p>
                            <p>Dubai</p>
                            <p>United Arab Emirates</p>
                            <p>Email address: <a href="mailto:commissioner@dp.difc.ae">commissioner@dp.difc.ae</a></p>
                            <p>Telephone number: +971 4 362 2222</p>
                            <p>We would, however, appreciate the chance to deal with your concerns before you approach the Commissioner of Data Protection so please contact us in the first instance.</p>
                        </blockquote>

                        <h3>Changes to the Privacy Policy and Your Obligation to Inform Us of Changes to Your Data</h3>
                        <p>(a) We keep our privacy policy under regular review and will inform you of any updates made to it. Historic versions can be obtained by contacting our data protection officer.</p>
                        <p>(b) It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</p>

                        <h3>Third Party Links</h3>
                        <blockquote>
                            <p>The Platform may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy policies or compliance therewith. When you leave our Platform, we encourage you to read the privacy policy of every website that you visit.</p>
                        </blockquote>

                        <h2>2. <span class="underline">THE DATA WE COLLECT ABOUT YOU</span></h2>
                        <p>(a) Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).</p>
                        <p>(b) We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:</p>
                        <ul>
                            <li>(i) "Identity Data" includes first name, maiden name, last name, username or similar identifier, marital status, title, date of birth and gender.</li>
                            <li>(ii) "Contact Data" includes billing address, delivery address, email address and telephone numbers.</li>
                            <li>(iii) "Financial Data" includes bank account and/or payment card details.</li>
                            <li>(iv) "Transaction Data" includes details about payments to and from you and other details of products and services you have purchased from us.</li>
                            <li>(v) "Technical Data" includes internet protocol (IP) address, your login data, browser type and version, time zone setting and Platform user location, browser plug-in types and versions, operating system and platform, and other software installed on the devices you use to access the Platform.</li>
                            <li>(vi) "Profile Data" includes your username and password, purchases or orders made by you, your biography, your interests, preferences, feedback and survey responses.</li>
                            <li>(vii) "Usage Data" includes information about how you use our Platform, products and services.</li>
                            <li>(viii) "Marketing and Communications Data" includes your preferences in receiving marketing from us and our third parties and your communication preferences.</li>
                        </ul>
                        <p>(c) We also collect, use and share "Aggregated Data" such as statistical or demographic data for a variety of purposes. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific Platform feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy policy.</p>
                        <p>(d) We do not collect any "<strong>Special Categories of Data</strong>" about you (this includes details about your race or ethnicity, religious or spiritual beliefs, sex life, sexual orientation, political opinions or affiliations, trade union membership, information about your health, and genetic and biometric data).</p>

                        <h3>If You Fail to Provide Personal Data</h3>
                        <blockquote>
                            <p>Where we need to collect personal data by law, or under the terms of a contract we have with you for the performance of the contract between us and the provision of our services to you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide access to the Platform). In this case, we may have to cancel your Subscription to the Platform in the manner set out in the FinBursa User Terms of Service (the "<strong>User Terms</strong>"). Please see the User Terms <a href="https://finbursa.com/terms">https://finbursa.com/terms</a> for further details.</p>
                        </blockquote>

                        <h2>3. <span class="underline">HOW IS YOUR PERSONAL DATA COLLECTED?</span></h2>
                        <h3>Methods of Collection</h3>
                        <p>We use different methods to collect data from and about you including through:</p>
                        <p>(a) <strong>Direct Interactions</strong>. You may give us your Identity, Contact and Financial Data by filling in forms or by corresponding with us via the Platform, by post, phone, email, or otherwise. This includes personal data you provide when you:</p>
                        <ul>
                            <li>(i) apply to create an account with, and become a user of, the Platform;</li>
                            <li>(ii) create an account on the Platform;</li>
                            <li>(iii) subscribe to our publications;</li>
                            <li>(iv) request marketing materials to be sent to you; or</li>
                            <li>(v) give us feedback or contact us.</li>
                        </ul>
                        <p>(b) <strong>Automated Technologies or Interactions</strong>. As you interact with our Platform, we may automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, server logs and other similar technologies. Please see our cookie policy <a href="https://finbursa.com/cookie">https://finbursa.com/cookie</a> for further details.</p>
                        <p>(c) <strong>Third Parties or Publicly Available Sources</strong>. We will receive personal data about you from various third parties and public sources as set out below:</p>
                        <ul>
                            <li>(i) Technical Data from analytics providers such as Google;</li>
                            <li>(ii) Contact, Financial and Transaction Data from providers of technical, payment and delivery services such as Bloomberg; or</li>
                            <li>(iii) Identity and Contact Data from data brokers or aggregators such as Crunchbase.</li>
                        </ul>

                        <h2>4. <span class="underline">HOW WE USE YOUR PERSONAL DATA</span></h2>
                        <blockquote>
                            <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>
                        </blockquote>
                        <p>(a) Where we need to perform the contract we are about to enter into or have entered into with you.</p>
                        <p>(b) Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</p>
                        <p>(c) Where we need to comply with applicable law.</p>

                        <h3><span class="underline">LAWFUL BASIS:</span></h3>
                        <ul>
                            <li><strong>Performance of a contract:</strong> Processing is necessary for the performance of a contract to which a data subject is a party, or in order to take steps at the request of a data subject prior to entering into such contract;</li>
                            <li><strong>Legitimate interests:</strong> Processing is necessary for the purpose of legitimate interests pursued by a controller or a third party to whom the personal data has been made available, except where such interests are overridden by the interests or rights of a data subject.</li>
                            <li><strong>Compliance with applicable law:</strong> Processing is necessary for compliance with applicable law that a controller is subject to.</li>
                        </ul>
                        <p>We will get your consent before sending third party direct marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time by contacting us.</p>

                        <h3>Purposes For Which We Will Use Your Personal Data</h3>
                        <p>We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.</p>
                        <p>Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.</p>

                        <table>
                            <thead>
                                <tr>
                                    <th>Purpose/Activity</th>
                                    <th>Type of data</th>
                                    <th>Lawful basis for processing including basis of legitimate interest</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>To register you as a new user of the Platform and to conduct our mandatory KYC and AML due diligence</td>
                                    <td>(a) Identity<br />(b) Contact<br />(c) Financial</td>
                                    <td>(a) Performance of a contract with you<br />(b) Necessary to comply with applicable law</td>
                                </tr>
                                <tr>
                                    <td>To process and deliver your order including:<br />(a) Manage payments, fees and charges<br />(b) Collect and recover money owed to us</td>
                                    <td>(a) Identity<br />(b) Contact<br />(c) Financial<br />(d) Transaction<br />(e) Marketing and Communications</td>
                                    <td>(a) Performance of a contract with you<br />(b) Necessary for our legitimate interests (to recover debts due to us)</td>
                                </tr>
                                <tr>
                                    <td>To manage our relationship with you which will include:<br />(a) Notifying you about changes to our terms or privacy policy<br />(b) Asking you to complete a questionnaire, leave a review or take a survey</td>
                                    <td>(a) Identity<br />(b) Contact<br />(c) Profile<br />(d) Marketing and Communications</td>
                                    <td>(a) Performance of a contract with you<br />(b) Necessary to comply with applicable law<br />(c) Necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)</td>
                                </tr>
                                <tr>
                                    <td>To administer and protect our business and this Platform (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</td>
                                    <td>(a) Identity<br />(b) Contact<br />(c) Technical</td>
                                    <td>(a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)<br />(b) Necessary to comply with applicable law</td>
                                </tr>
                                <tr>
                                    <td>To deliver relevant Platform content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you</td>
                                    <td>(a) Identity<br />(b) Contact<br />(c) Profile<br />(d) Usage<br />(e) Marketing and Communications<br />(f) Technical</td>
                                    <td>Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)</td>
                                </tr>
                                <tr>
                                    <td>To use data analytics to improve our Platform, products/services, marketing, customer relationships and experiences</td>
                                    <td>(a) Technical<br />(b) Usage</td>
                                    <td>Necessary for our legitimate interests (to define types of customers for our products and services, to keep our Platform updated and relevant, to develop our business and to inform our marketing strategy)</td>
                                </tr>
                                <tr>
                                    <td>To make suggestions and recommendations to you about goods or services that may be of interest to you</td>
                                    <td>(a) Identity<br />(b) Contact<br />(c) Technical<br />(d) Usage<br />(e) Profile<br />(f) Marketing and Communications</td>
                                    <td>Necessary for our legitimate interests (to develop our products/services and grow our business)</td>
                                </tr>
                                <tr>
                                    <td>Any other purpose disclosed by us when you provide the personal data from time to time</td>
                                    <td>As may be applicable</td>
                                    <td>(a) Performance of a contract with you<br />(b) Necessary to comply with applicable law</td>
                                </tr>
                            </tbody>
                        </table>

                        <h3>Marketing</h3>
                        <p>We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising.</p>

                        <h4>Promotional Offers From Us</h4>
                        <p>We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing).</p>
                        <p>You will receive marketing communications from us (including, but not limited to, to inform you of new Projects (as defined in the User Terms) which match your preferred criteria or tell you about new features of the Platform) if you have subscribed to the Platform and you have expressly opted in for receiving that marketing.</p>

                        <h4>Third Party Marketing</h4>
                        <p>We will get your express opt-in consent before we share your personal data with any third party for marketing purposes.</p>

                        <h4>Opting Out</h4>
                        <p>You can ask us or third parties to stop sending you marketing messages at any time by logging into the Platform and checking or unchecking relevant boxes to adjust your marketing preferences, by following the opt-out links on any marketing message sent to you, or by contacting us at any time.</p>
                        <p>Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a product/service purchase, warranty registration, product/service experience or other transactions.</p>

                        <h4>Cookies</h4>
                        <p>You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this Platform may become inaccessible or not function properly. For more information about the cookies we use, please see <a href="https://finbursa.com/cookie">https://finbursa.com/cookie</a>.</p>

                        <h3>Change of Purpose</h3>
                        <p>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. Upon request, we will provide you with further information as to how the processing for the new purpose is compatible with the original purpose.</p>
                        <p>If we need to use your personal data for a purpose unrelated to the original purpose, we will notify you in advance and we will explain the legal basis which allows us to do so in order for you to be able to make an informed decision at that point on how we continue to use your personal information.</p>
                        <p>Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</p>

                        <h2>5. <span class="underline">DISCLOSURES OF YOUR PERSONAL DATA</span></h2>
                        <p>We may share your personal data with the parties set out below for the purposes set out in the table above.</p>
                        <p>(a) <strong>Internal Third Parties</strong>:</p>
                        <blockquote>
                            <p>FinBursa application is a global investment information marketplace dedicated to qualified investors and institutions. This information marketplace is composed of a central FinBursa marketplace and a potential network of alternative marketplaces provided to regulated financial institutions under a SaaS (software as a service) agreement. By electing to interact with these alternative marketplaces you acknowledge and consent that we may share your personal data (as set out in the table above) for the purpose of enabling your elected interaction.</p>
                            <p>We encourage you to be sure the recipients are authenticated to your satisfaction before you send them any documents or sensitive information.</p>
                        </blockquote>
                        <p>(b) <strong>External Third Parties</strong>:</p>
                        <blockquote>
                            <p>The FinBursa platform uses third party service providers to execute certain tasks through <strong>application programming interface</strong> (<strong>API</strong>) for a limited number of the purposes set out in the table above.</p>
                            <ul>
                                <li>RiskScreen -- KYC and AML Technology provider. Please refer to <a href="https://www.riskscreen.com/riskscreen-privacy-policy/">https://www.riskscreen.com/riskscreen-privacy-policy/</a> for further details.</li>
                                <li>Pandadoc -- Document Management Software. Please refer to <a href="https://www.pandadoc.com/privacy-policy/">https://www.pandadoc.com/privacy-policy/</a> for further details.</li>
                            </ul>
                            <p>This Privacy Policy only addresses the use and disclosure of information we collect from you. If you disclose your information to other parties using the Website, or visit other websites linked through the Website, different rules may apply to their use or disclosure of the information you disclose to them. We do not control these third-party websites and are not responsible for their privacy policies or compliance therewith.</p>
                        </blockquote>
                        <p>(c) Third parties to whom we may choose to sell, transfer or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy policy. If we are involved in a merger, acquisition or asset sale, your personal data may be transferred. However, to the extent required by applicable law we will provide notice before your personal data is transferred and becomes subject to a different privacy policy.</p>
                        <p>(d) Under certain circumstances, we may be required to disclose your personal data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
                        <p>(e) In good faith where such action is necessary to:</p>
                        <ul>
                            <li>1. To comply with a legal obligation.</li>
                            <li>2. To protect and defend the rights or property of FinBursa.</li>
                            <li>3. To prevent or investigate possible wrongdoing in connection with the Platform.</li>
                            <li>4. To protect the personal safety of users of the Platform.</li>
                            <li>5. To protect against legal liability.</li>
                        </ul>
                        <p>We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>

                        <h2>6. <span class="underline">INTERNATIONAL TRANSFERS</span></h2>
                        <p>Whenever we transfer your personal data out of the DIFC, we ensure a similar degree of protection is afforded to it. We do this by ensuring that we will only transfer your personal data to countries or territories (where the receiver is located or a sector which covers the receiver) where the applicable law provides for the same degree of protection (as set out in Articles 26 (2) and (3) of the Data Protection Law DIFC Law No. 5 of 2020 (the "<strong>DP Law</strong>")), the controller or processor in question has provided appropriate safeguards (described in Article 27(2) of the DP Law), and on the condition that enforceable data subject rights and effective legal remedies for data subjects are available. Further information on the specific mechanism used by us when transferring your personal data outside of the DIFC can be made available upon request. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to such transfers and you explicitly retain the right to withdraw your consent at any given time (subject to section 2 of the Privacy Policy in relation to data under the terms of a contract that we have with you for the performance of the contract between us and the provision of our services to you).</p>

                        <h2>7. <span class="underline">DATA SECURITY</span></h2>
                        <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>
                        <p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>

                        <h2>8. <span class="underline">DATA RETENTION</span></h2>
                        <p>We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.</p>
                        <p>To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.</p>
                        <p>In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.</p>

                        <h2>9. <span class="underline">YOUR LEGAL RIGHTS</span></h2>
                        <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data:</p>
                        <p>You have the right to:</p>
                        <ul>
                            <li><strong>Request access</strong> to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it. Where the condition for processing your personal data is your consent or the performance of a contract and is carried out by automated means, you would also have the right to data portability. Specifically, you would be able to obtain any personal data you have provided to us in a structured, commonly used and machine-readable format.</li>
                            <li><strong>Request correction</strong> of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</li>
                            <li><strong>Request erasure</strong> of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</li>
                            <li><strong>Object to processing</strong> of your personal data, on reasonable grounds, where we are relying on a legitimate interest (or those of a third party). You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and objections in accordance with the DP Law.</li>
                            <li><strong>Object to direct marketing.</strong> You have the right to be informed before your personal data is used on your behalf for the purposes of direct marketing. You can object to your personal data being processed for direct marketing purposes at any times.</li>
                            <li><strong>Request restriction of processing</strong> of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:
                                <ul>
                                    <li>If you want us to establish the data's accuracy.</li>
                                    <li>Where our use of the data is unlawful but you do not want us to erase it.</li>
                                    <li>Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.</li>
                                    <li>You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</li>
                                </ul>
                            </li>
                            <li><strong>Request the transfer</strong> of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</li>
                            <li><strong>Withdraw consent at any time</strong> where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</li>
                            <li><strong>Seek compensation</strong> from court where you have suffered any damage due to a breach of DIFC data protection laws or regulations. Any such compensation would be in addition to, and exclusive of, any fine imposed on us.</li>
                        </ul>
                        <p>If you wish to exercise any of the rights set out above, please contact our data protection officer. Please note that we may ask you to verify your identity before responding to such requests.</p>

                        <h3>No Fee Usually Required</h3>
                        <p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive; we also reserve the right refuse to comply with your request in these circumstances.</p>

                        <h3>What We May Need From You</h3>
                        <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>

                        <h3>Time Limit To Respond</h3>
                        <p>We try to respond to all legitimate requests within one month. If your request is particularly complex or you have made numerous requests, we may notify you within one month to increase the period by a further two months (citing the reasons for the delay).</p>

                        <p><span class="underline">This Privacy Policy was last updated on March 1, 2025</span></p>

                        
                    </div>
                </React.Fragment>
            </div>
        </div>
    );
};

export default PolicySection;










import './ContactSupport.css';
import '../../index.css';
import { useState } from 'react';
import CloseIcon from '../../assets/close-icon';
import { useContactForm } from './ContactFormContext';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { isSp } from '../../utils/isSp'
import { useConfig } from "../../config/ConfigContext";

const ContactSupport = (props) => {
    const isSpCheck = isSp();

    const { accentColor } = useConfig();

    const defaultIconStyle = {
        fill: isSpCheck ? (accentColor || '#286684') : '#286684',
    };
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        position: '',
        company: '',
        email: '',
        message: '',
    });

    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [phone, setPhone] = useState('');
    const [countryCode, setCountryCode] = useState('');


    const { handleCloseContactForm } = useContactForm();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (errors[name]) {
            const updatedErrors = { ...errors };
            delete updatedErrors[name];
            setErrors(updatedErrors);
        }
        if (Object.keys(errors).length === 1 && errors[name]) {
            setErrorMessage('');
        }
    };


    const isValidPhoneNumber = (phone) => {
        return phone && phone.length >= 6 && /^\+?[1-9]\d{5,14}$/.test(phone);
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.firstName) newErrors.firstName = 'First name is required';
        else if (formData.firstName.length > 20) newErrors.firstName = 'First name cannot exceed 20 characters';

        if (!formData.lastName) newErrors.lastName = 'Last name is required';
        else if (formData.lastName.length > 20) newErrors.lastName = 'Last name cannot exceed 20 characters';

        if (!formData.position) newErrors.position = 'Position is required';
        else if (formData.position.length > 20) newErrors.position = 'Position cannot exceed 20 characters';

        if (!formData.company) newErrors.company = 'Company name is required';
        else if (formData.company.length > 50) newErrors.company = 'Company name cannot exceed 50 characters';

        if (!formData.email) newErrors.email = 'Work email is required';
        else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Invalid email format';

        if (!formData.message) newErrors.message = 'Message is required';
        else if (formData.message.length > 250) newErrors.message = 'Message cannot exceed 250 characters';

        if (!isValidPhoneNumber(phone)) {
            newErrors.phone = 'Enter a valid phone number';
        }
        return newErrors;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
    
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setIsSubmitting(true);
    
            try {
                const formattedCountryCode = countryCode.toUpperCase();
                const formattedPhoneNumber = phone.startsWith('+') ? phone : `+${phone}`;
                const response = await fetch(`${process.env.REACT_APP_API_URL}/user/api/v2/contact-us`, {
                    method: 'POST',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        name: formData.firstName + ' ' + formData.lastName,
                        email: formData.email,
                        message: formData.message,
                        company: formData.company,
                        position: formData.position,
                        phone: {
                            number: formattedPhoneNumber,
                            countryCode: formattedCountryCode || 'US',
                        },
                        type: props.type
                    }),
                });
    
                if (!response.ok) {
                    const errorResponse = await response.json();
                    if (errorResponse && errorResponse.length > 0) {
                        // Extract the description from the error response
                        const errorDescription = errorResponse[0].description;
                        setErrorMessage(errorDescription);
    
                        // Set specific field errors if needed
                        if (errorResponse[0].data && errorResponse[0].data.field) {
                            setErrors({
                                ...errors,
                                [errorResponse[0].data.field]: errorDescription,
                            });
                        }
                    } else {
                        throw new Error('Failed to send your message. Please try again.');
                    }
                } else {
                    setIsSubmitted(true);
                    setFormData({
                        firstName: '',
                        lastName: '',
                        position: '',
                        company: '',
                        email: '',
                        message: '',
                    });
                    setPhone('');
                    setCountryCode('');
                }
            } catch (error) {
                setErrorMessage(error.message);
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    return (
        <div className="contact-support-modal">
            <div className="contact-support-form">
                {isSubmitted ? (
                    <>
                        <div className="contact-support-title-section">
                            <p>Message Sent</p>
                            <div className="contact-us-close-icon" onClick={handleCloseContactForm}>
                                <CloseIcon style={defaultIconStyle} />
                            </div>
                        </div>
                        <div className="success-message">
                            <p>A member of our team will be in touch with you shortly.</p>

                        </div>
                    </>

                ) : (
                    <>
                        <div className="contact-support-title-section-parent">
                            <div className="contact-us-close-icon" onClick={handleCloseContactForm}>
                                <CloseIcon style={defaultIconStyle} />
                            </div>
                            {/* <p className='contact-italic'>Finbursa is coming soon</p> */}
                            <div className="contact-support-title-section">
                                <p>Our team will contact you soon</p>
                            </div>
                        </div>

                        <form className="contact-form">
                            <div className="form-row">
                                <div className="form-field">
                                    <input
                                        type="text"
                                        className={`form-input ${errors.firstName ? 'error' : ''}`}
                                        placeholder="First name"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                    />
                                    {errors.firstName && <p className="error">{errors.firstName}</p>}
                                </div>

                                <div className="form-field">
                                    <input
                                        type="text"
                                        className={`form-input ${errors.lastName ? 'error' : ''}`}
                                        placeholder="Last Name"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                    />
                                    {errors.lastName && <p className="error">{errors.lastName}</p>}
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-field">
                                    <input
                                        type="text"
                                        className={`form-input ${errors.position ? 'error' : ''}`}
                                        placeholder="Position"
                                        name="position"
                                        value={formData.position}
                                        onChange={handleInputChange}
                                    />
                                    {errors.position && <p className="field-error">{errors.position}</p>}
                                </div>
                                <div className="form-field">
                                    <input
                                        type="text"
                                        className={`form-input ${errors.company ? 'error' : ''}`}
                                        placeholder="Company Name"
                                        name="company"
                                        value={formData.company}
                                        onChange={handleInputChange}
                                    />
                                    {errors.company && <p className="field-error">{errors.company}</p>}
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-field">
                                    <input
                                        type="email"
                                        name="email"
                                        className={`form-input ${errors.email ? 'error' : ''}`}
                                        placeholder="Work Email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                    {errors.email && <p className="field-error">{errors.email}</p>}
                                </div>

                                <div className={`form-field ${errors.phone ? 'error' : ''}`}>
                                    <PhoneInput
                                        country={countryCode || 'ae'}
                                        value={phone}
                                        onChange={(value, countryData) => {
                                            setPhone(value);
                                            setCountryCode(countryData ? countryData.countryCode.toUpperCase() : 'AE');

                                            if (errors.phone) {
                                                const updatedErrors = { ...errors };
                                                delete updatedErrors.phone;
                                                setErrors(updatedErrors);
                                                if (Object.keys(updatedErrors).length === 0) {
                                                    setErrorMessage('');
                                                }
                                            }
                                        }}
                                        containerClass={`phone-input-container ${errors.phone ? 'error' : ''}`}
                                        inputClass={`form-input ${errors.phone ? 'error' : ''}`}
                                        buttonClass="phone-input-dropdown"
                                        dropdownClass="phone-dropdown-menu"
                                        searchClass="phone-dropdown-search"
                                        inputProps={{
                                            placeholder: "Phone Number",
                                            required: true,
                                        }}
                                    />
                                    {errors.phone && <p className="field-error">{errors.phone}</p>}
                                </div>
                            </div>

                            <div className="textarea-field">
                                <textarea
                                    name="message"
                                    className={`form-textarea ${errors.message ? 'error' : ''}`}
                                    placeholder="Message"
                                    rows="6"
                                    value={formData.message}
                                    onChange={handleInputChange}
                                ></textarea>
                                {errors.message && <p className="field-error">{errors.message}</p>}
                            </div>

                            <button
                                type="submit"
                                className="global-button send-message-button"
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Sending...' : 'Send'}
                            </button>
                        </form>
                    </>

                )}
            </div>
        </div>
    );
};

export default ContactSupport;

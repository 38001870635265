import React, { useState, useEffect } from 'react';
import './MarketplaceMainSection.css';
import '../../index.css';
import useScreenSize from '../../hooks/useScreenSize';
import { useContactForm } from '../ContactSupport/ContactFormContext';

const MarketplaceMainSection = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const { isMobile, isTablet } = useScreenSize();
    const { handleOpenContactForm } = useContactForm();
    const togglePopup = () => {
        setIsPopupOpen((prev) => !prev);
    };

    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('video-popup')) {
            setIsPopupOpen(false);
        }
    };

    const handleNavigation = () => {
        window.location.href = "/roles";
    };

    return (
        <div className="marketplace-main-cover">
            <div className="cover-content">
                <p className="marketplace-main-section-title ">Marketplace</p>
                <p className="marketplace-main-section-text">Where Opportunities <br/> Meet Capital</p> <br />
               
                <div className="button-section">
                    <button className="global-button btn" onClick={handleNavigation}>Join Now</button>
                    <button className="watch-video-link" onClick={() => handleOpenContactForm("demo")}>
                        <span>Book a demo</span>
                        <svg
                            className="icon-stroke"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 8 14"
                            aria-hidden="true"
                        >
                            <path
                                d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            

        </div>
    );
};

export default MarketplaceMainSection;

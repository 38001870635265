import React, { useState, useEffect } from 'react';
import './RolesSection.css';
import '../../index.css';
import UserTypes from '../UserTypes/UserTypes';


const RolesSection = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
        setIsPopupOpen((prev) => !prev);
    };

    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('video-popup')) {
            setIsPopupOpen(false);
        }
    };

    const handleNavigation = () => {
        window.location.href = "/roles";
    };

    return (
        <div className="roles-section">
            <div className="cover-content roles-content">
                <p className="title">Signup to join <br />FINBURSA </p> <br />
                <p className="roles-small-title">Select your role</p>
            </div>
            <UserTypes/>


        </div>
    );
};

export default RolesSection;





import React from 'react';

const PhoneIcon = ({ style = {}, className = "" }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill={style.fill || "currentColor"} xmlns="http://www.w3.org/2000/svg">
            <path d="M4.41333 7.19336C5.37333 9.08003 6.92 10.62 8.80667 11.5867L10.2733 10.12C10.4533 9.94003 10.72 9.88003 10.9533 9.96003C11.7 10.2067 12.5067 10.34 13.3333 10.34C13.7 10.34 14 10.64 14 11.0067V13.3334C14 13.7 13.7 14 13.3333 14C7.07333 14 2 8.9267 2 2.6667C2 2.30003 2.3 2.00003 2.66667 2.00003H5C5.36667 2.00003 5.66667 2.30003 5.66667 2.6667C5.66667 3.50003 5.8 4.30003 6.04667 5.0467C6.12 5.28003 6.06667 5.54003 5.88 5.7267L4.41333 7.19336Z" fill={style.fill || "#286684"} />
        </svg>
    );
};

export default PhoneIcon;

import React, { useState, useEffect } from 'react';
import './UserTypes.css';
import '../../index.css';
import ArrowButton from '../../components/ArrowButton/ArrowButton';

import { ReactComponent as StrategicPartner } from '../../assets/svg/strategicPartner.svg';
import { ReactComponent as Investor } from '../../assets/svg/investor.svg';
import { ReactComponent as Issuer } from '../../assets/svg/issuer.svg';
import { ReactComponent as Advisor } from '../../assets/svg/advisor.svg';
import useScreenSize from '../../hooks/useScreenSize';

const UserTypes = () => {

    const { isMobile, isTablet } = useScreenSize();

    const handleStrategicPartnerClick = () => {
        window.location.href = "/roles/strategic-partner";
    };

    const handleInvestorClick = () => {
        window.location.href = "/roles/investor";
    };

    const handleIssuerClick = () => {
        window.location.href = "/roles/issuer";
    };

    const handleAdvisorClick = () => {
        window.location.href = "/roles/advisor";
    };

    return (
        <div className="user-types">
            {!isTablet && !isMobile && (
                <div className="user-types-cards">
                    <div className="card">
                        <p className="card-title">Strategic Partner</p>
                        <div className="card-line"></div>
                        <div className="card-content">
                            <p className="card-content-text">
                                Leverage fintech innovation to optimize operations, expand  reach and business through  collaboration and network empowerment.
                            </p>
                            <div className="card-image">
                                <StrategicPartner />
                            </div>
                            <button className="global-button type-button" onClick={handleStrategicPartnerClick}>Join as a Strategic Partner</button>
                            <ArrowButton onClick={handleStrategicPartnerClick} />
                        </div>
                    </div>
                    <div className="card">
                        <p className="card-title">Investor</p>
                        <div className="card-line"></div>
                        <div className="card-content">
                            <p className="card-content-text">
                                Access cross-asset global opportunities, diverse offerings, and a network of like-minded experts with ease.
                            </p>
                            <div className="card-image">
                                <Investor />
                            </div>
                            <button className="global-button type-button" onClick={handleInvestorClick}>Join as an Investor</button>
                            <ArrowButton onClick={handleInvestorClick} />

                        </div>
                    </div>
                    <div className="card">
                        <p className="card-title">Issuer</p>
                        <div className="card-line"></div>
                        <div className="card-content">
                            <p className="card-content-text">
                                Raise capital across asset classes globally and connect with a wide array of investors and advisors seamlessly.
                            </p>
                            <div className="card-image">
                                <Issuer />
                            </div>
                            <button className="global-button type-button" onClick={handleIssuerClick}>Join as an Issuer</button>
                            <ArrowButton onClick={handleIssuerClick} />
                        </div>
                    </div>
                    <div className="card">
                        <p className="card-title">Advisor</p>
                        <div className="card-line"></div>
                        <div className="card-content">
                            <p className="card-content-text">
                                Access global networks, investors, and tools to expand reach and grow business.
                            </p>
                            <div className="card-image">
                                <Advisor />
                            </div>
                            <button className="global-button type-button" onClick={handleAdvisorClick}>Join as an Advisor</button>
                            <ArrowButton onClick={handleAdvisorClick} />


                        </div>
                    </div>

                </div>
            )}

            {(isMobile || isTablet) && (
                <div className="user-types-mobile-container">
                    <div className="user-types-cards">
                        <div className="card">
                            <p className="card-title">Strategic Partner</p>
                            <div className="card-line"></div>
                            <div className="card-content">
                                <p className="card-content-text">
                                    Leverage fintech innovation to optimize operations, expand  reach and business through  collaboration and network empowerment.
                                </p>
                                <div className="card-image">
                                    <StrategicPartner />
                                </div>
                                <button className="global-button type-button">Join as a Strategic Partner</button>
                                <ArrowButton onClick={handleStrategicPartnerClick} />
                            </div>
                        </div>
                        <div className="card">
                            <p className="card-title">Issuer</p>
                            <div className="card-line"></div>
                            <div className="card-content">
                                <p className="card-content-text">
                                    Raise capital across asset classes globally and connect with a wide array of investors and advisors seamlessly.
                                </p>
                                <div className="card-image">
                                    <Issuer />
                                </div>
                                <button className="global-button type-button">Join as an Issuer</button>
                                <ArrowButton onClick={handleIssuerClick} />
                            </div>
                        </div>

                    </div>
                    <br /><br />
                    <div className="user-types-cards">
                        <div className="card">
                            <p className="card-title">Investor</p>
                            <div className="card-line"></div>
                            <div className="card-content">
                                <p className="card-content-text">
                                    Access cross-asset global opportunities, diverse offerings, and a network of like-minded experts with ease.
                                </p>
                                <div className="card-image">
                                    <Investor />
                                </div>
                                <button className="global-button type-button">Join as an Investor</button>
                                <ArrowButton onClick={handleInvestorClick} />

                            </div>
                        </div>
                        <div className="card">
                            <p className="card-title">Advisor</p>
                            <div className="card-line"></div>
                            <div className="card-content">
                                <p className="card-content-text">
                                    Access global networks, investors, <br /> and tools to expand reach and grow business.
                                </p>
                                <div className="card-image">
                                    <Advisor />
                                </div>
                                <button className="global-button type-button">Join as an Advisor</button>
                                <ArrowButton onClick={handleAdvisorClick} />


                            </div>
                        </div>
                    </div>
                </div>
            )}


        </div >



    );
};

export default UserTypes;


import NotFound from '../components/NotFound/NotFound';



function NotFoundPage() {
    return (
        <div className="App">
            <NotFound/>
        </div>
    );
}

export default NotFoundPage;
import React from 'react';
import './PublicPageBenefitsSection.css';
import '../../index.css';
import { ReactComponent as PlusIcon } from '../../assets/svg/plus.svg';
import { useContactForm } from '../ContactSupport/ContactFormContext';

const PublicPageBenefitsSection = ({ benefits, signuptext, onSignupClick }) => {
    const { handleOpenContactForm } = useContactForm();
    return (
        <div className='public-page-benefits-page'>
            <div className="public-page-benefits-section">
                <div className='plus-icon'>
                    <PlusIcon />
                </div>
                <p className='benefits-title'>Benefits</p>

                <div>
                    {benefits.map((benefit, index) => (
                        <React.Fragment key={index}>
                            <p className="benefits-text">{benefit.text}</p>
                            <p className="benefits-subtext">{benefit.subtext}</p>
                            {index < benefits.length - 1 && <div className="how-it-works-line"></div>}
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div className='benefits-signup-section'>
                <div className="benefits-signup">
                    <div className="benefits-signup-title">{signuptext}</div>
                    <button className="global-button-coming-soon" onClick={() => handleOpenContactForm('pre-register')}>Pre-Register</button>
                </div>
            </div>
        </div>
    );
};


export default PublicPageBenefitsSection;

import React from 'react';
const defaultStyles = {};

export default ({ style, className }) => {
    const customStyles = Object.assign({}, defaultStyles, style);

    return (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.74996 9.19727C12.692 9.19727 16.737 11.7603 16.999 16.9093C17.009 17.1077 16.9399 17.3021 16.8068 17.4497C16.6737 17.5972 16.4874 17.6859 16.289 17.6963C14.209 17.8023 4.57596 17.8673 1.21196 17.6963C1.01332 17.6862 0.826817 17.5976 0.693481 17.45C0.560144 17.3024 0.490894 17.1079 0.500962 16.9093C0.762962 11.7613 4.80796 9.19727 8.74996 9.19727ZM8.74996 0.197266C7.7554 0.197266 6.80157 0.592354 6.09831 1.29562C5.39505 1.99888 4.99996 2.9527 4.99996 3.94727C4.99996 4.94183 5.39505 5.89565 6.09831 6.59892C6.80157 7.30218 7.7554 7.69727 8.74996 7.69727C9.74452 7.69727 10.6984 7.30218 11.4016 6.59892C12.1049 5.89565 12.5 4.94183 12.5 3.94727C12.5 2.9527 12.1049 1.99888 11.4016 1.29562C10.6984 0.592354 9.74452 0.197266 8.74996 0.197266Z" fill="white" />
        </svg>
    );
};

import React, { useState, useEffect } from 'react';
import './TechnologyPartners.css';
import '../../index.css';
import { ReactComponent as Aws } from '../../assets/svg/aws.svg';
import { ReactComponent as Mailgun } from '../../assets/svg/mailgun.svg';
import { ReactComponent as Pandadoc } from '../../assets/svg/pandadoc.svg';
import { ReactComponent as RiskScreen } from '../../assets/svg/risk-screen.svg';
import useScreenSize from '../../hooks/useScreenSize';

const TechnologyPartners = () => {
    const { isMobile, isTablet } = useScreenSize();

    return (
        <div className="tech-partners">
            <p className="tech-title">Our technology partners</p>
            <div className="partners">
                {(isMobile || isTablet) ? (
                    <>
                        <div className="partner-row">
                            <Aws />
                            <div className="separator"></div>
                            <Mailgun />
                        </div> <br />
                        <div className="partner-row">
                            <RiskScreen />
                            <div className="separator"></div>
                            <Pandadoc />
                        </div>
                    </>
                ) : (
                    <>
                        <Aws />
                        <div className="separator"></div>
                        <Mailgun />
                        <div className="separator"></div>
                        <Pandadoc />
                        <div className="separator"></div>
                        <RiskScreen />
                    </>
                )}
            </div>
        </div>
    );
};

export default TechnologyPartners;


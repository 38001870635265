import MainUsers from '../components/MainUsers/MainUsers';
import UserTypes from '../components/UserTypes/UserTypes';
import TechnologyPartners from '../components/TechnologyPartners/TechnologyPartners';
import SelectedProjects from '../components/SelectedProjects/SelectedProjects';
import HowItWorks from '../components/HowItWorks/HowItWorks';
import Testimonials from '../components/Testimonials/Testimonials';
import Footer from '../components/Footer/Footer';
import ContactSupport from '../components/ContactSupport/ContactSupport';
import Header from '../components/Header/Header';
import MainCover from '../components/MainCover/MainCover';
import { useContactForm } from '../components/ContactSupport/ContactFormContext';
import { isSp } from '../utils/isSp'
function HomePage() {
    const { isContactFormOpen, type } = useContactForm();
    
    return (
        <div className="App">

            {isSp() ? (
                <>
                    <Header />
                    <MainCover />
                    <SelectedProjects />
                    <Footer />
                    {isContactFormOpen && <ContactSupport type={type} />}
                </>

            ) : (
                <>
                    <Header />
                    <MainCover />
                    <MainUsers />
                    <UserTypes />
                    <TechnologyPartners />
                    <SelectedProjects />
                    <HowItWorks />
                    {/* <Testimonials /> */}
                    <Footer />
                    {isContactFormOpen && <ContactSupport type={type} />}
                </>
            )}

        </div>
    );
}

export default HomePage;

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import CookiesSection from '../components/CookiesSection/CookiesSection';
import './CookiesPage.css'; // Add this for the CSS
import { useContactForm } from '../components/ContactSupport/ContactFormContext';
import ContactSupport from '../components/ContactSupport/ContactSupport';

function CookiesPage() {
    const { isContactFormOpen, type } = useContactForm();
    return (
        <div className="App">
            <div className="content">
                <Header />
                <CookiesSection />
            </div>
            <Footer />
            {isContactFormOpen && <ContactSupport type={type} />}
        </div>
    );
}

export default CookiesPage;

import React from 'react';
import './PublicPageMainSection.css';
import '../../index.css';
import { useContactForm } from '../ContactSupport/ContactFormContext';

const PublicPageMainSection = ({ data }) => {
    const { title, subtitle, text, buttonLabel, image: ImageComponent, onClick, onSignupClick, className } = data;
    const { handleOpenContactForm } = useContactForm();
    return (
        <div className="public-page-main-section">
            <div className="cover-content sp-content">
                <p className="about-title">{title}</p>
                <p className="sp-title">{subtitle}</p>
                <p className="sp-text">{text}</p>
                <button className={className} onClick={onSignupClick}>{buttonLabel}</button>
            </div>
            <div className="image-container">
                {ImageComponent && <ImageComponent />}
            </div>
        </div>
    );
};

export default PublicPageMainSection;

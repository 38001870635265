import React, { useState, useEffect } from 'react';
import './AboutWhoWeAre.css';
import '../../index.css';
import { ReactComponent as WhoWeAre } from '../../assets/svg/who-we-are.svg';
import useScreenSize from '../../hooks/useScreenSize';


const AboutWhoWeAre = () => {


    return (
        <div className="about-who-we-are">
            <div className='about-who-we-are-content'>
                <div className='about-who-we-are-text'>
                    <p className='who-we-are-title'>Who we are</p>
                    <p className='who-we-are-text'>Founded in 2024, FinBursa is part of the DIFC Innovation Hub and is licensed by the Dubai International Financial Centre. It was born from years of brainstorming by M&A and fintech leaders who have navigated the complexities of the global investment landscape. Recognizing the setbacks and inefficiencies of traditional tools, they envisioned a single, unified platform to address cross-asset and cross-border investment challenges. Today, FinBursa delivers on that vision, redefining how capital and opportunities connect worldwide.</p>
                </div>
                <div className="about-who-we-are-svg">
                    <WhoWeAre />
                </div>



            </div>

        </div>
    );
};

export default AboutWhoWeAre;
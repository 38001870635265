export const hoverColor = (color) => {
  if (!color || typeof color !== "string" || !color.startsWith("#")) {
    console.warn("Invalid color received:", color);
    return "#000000"; 
  }

  const hexToRgb = (hex) => {
    hex = hex.replace(/^#/, '');
    if (hex.length === 3) {
      hex = hex.split('').map(x => x + x).join('');
    }
    if (hex.length !== 6) return null;
    return {
      r: parseInt(hex.substring(0, 2), 16),
      g: parseInt(hex.substring(2, 4), 16),
      b: parseInt(hex.substring(4, 6), 16),
    };
  };

  const rgbToHex = (r, g, b) => 
    `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase()}`;

  const rgb = hexToRgb(color);
  if (!rgb) {
    console.warn("Failed to convert color to RGB:", color);
    return color;
  }

  const mixWithBlack = (channel) => Math.round(channel * 0.7);  // Mix 30% black

  const r = mixWithBlack(rgb.r);
  const g = mixWithBlack(rgb.g);
  const b = mixWithBlack(rgb.b);

  return rgbToHex(r, g, b);
};

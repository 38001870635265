import React from 'react';
import './NotFound.css';
import { useConfig } from "../../config/ConfigContext";
import { isSp } from '../../utils/isSp';

const NotFound = ({ benefits, signuptext, onSignupClick }) => {
    const isSpCheck = isSp();
    const { accentColor } = useConfig();

    const redirectToHome = () => {
        window.location.href = '/'; 
    };

    return (
        <div className="p404-container">
            <h1 className="not-found-text" style={{ fontSize: '50px'}}>Page not found</h1>
            <button className='global-button' onClick={redirectToHome} type="button">Go to homepage</button>
        </div>
    );
};

export default NotFound;

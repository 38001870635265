import React from 'react';

const EmailIcon = ({ style = {}, className = "" }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill={style.fill || "currentColor"} xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3333 2.6667H2.66668C1.93334 2.6667 1.34001 3.2667 1.34001 4.00003L1.33334 12C1.33334 12.7334 1.93334 13.3334 2.66668 13.3334H13.3333C14.0667 13.3334 14.6667 12.7334 14.6667 12V4.00003C14.6667 3.2667 14.0667 2.6667 13.3333 2.6667ZM13.3333 5.33336L8.00001 8.6667L2.66668 5.33336V4.00003L8.00001 7.33336L13.3333 4.00003V5.33336Z" fill={style.fill || "#286684"} />
        </svg>
    );
};

export default EmailIcon;

import React from 'react';

const TwitterIcon = ({ style = {}, className = "" }) => {
    return (
        <svg width="27" height="28" viewBox="0 0 27 28" fill={style.fill || "currentColor"} xmlns="http://www.w3.org/2000/svg">
            <path d="M16.0687 11.8558L26.1198 3.05176e-05H23.7379L15.0107 10.2945L8.04012 3.05176e-05H0L10.5406 15.5669L0 28H2.38185L11.598 17.1282L18.9599 28H27L16.0675 11.8558H16.0687ZM12.806 15.7041L11.7382 14.154L3.24 1.81947H6.89886L13.7569 11.7735L14.8247 13.3236L23.739 26.2629H20.0802L12.806 15.7041Z" fill={style.fill || "#286684"} />
        </svg>
    );
};

export default TwitterIcon;

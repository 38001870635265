import React, { useState, useEffect } from 'react';
import './HowItWorks.css';
import '../../index.css';
import { ReactComponent as HowItWorksImage } from '../../assets/svg/how-it-works2.svg';
import { ReactComponent as HowItWorksImageMobile } from '../../assets/svg/how-it-works.svg';
import { useContactForm } from '../ContactSupport/ContactFormContext';
import useScreenSize from '../../hooks/useScreenSize';

const HowItWorks = () => {
    const { handleOpenContactForm } = useContactForm();
    const { isMobile, isTablet } = useScreenSize();

    return (
        <div className="how-it-works-section">
            {!isTablet && !isMobile && (
                <div className="how-it-works-content">
                    <div className="how-it-works-text-section">
                        <p className="how-it-works-title">How it works</p>
                        <div>
                            <p className="how-it-works-text">Comprehensive & Independent</p>
                            <div className="how-it-works-line"></div>
                            <p className="how-it-works-text">Global, Cross-Border Access</p>
                            <div className="how-it-works-line"></div>
                            <p className="how-it-works-text">Collaborative Disruption</p>
                            <div className="how-it-works-line"></div>
                            <p className="how-it-works-text">Cost-Efficient & Transparent</p>
                            <div className="how-it-works-line"></div>
                            <p className="how-it-works-text">Multi-Asset, Multi-Institutional Opportunities</p>
                        </div>
                        <button className="global-button" onClick={() => handleOpenContactForm("demo")}>Book a demo</button>

                    </div>
                    <div className="how-it-works-image">
                        <HowItWorksImage />
                    </div>

                </div>
            )}
            {(isMobile || isTablet) && (
                <div className="how-it-works-content">
                    <div className="how-it-works-text-section">
                        <p className="how-it-works-title">How it works</p>
                        <div className="how-it-works-image">
                            <HowItWorksImageMobile />
                        </div>

                    </div>
                    <div className="how-it-works-text-mobile">
                        <p className="how-it-works-text">Comprehensive & Independent</p>
                        <div className="how-it-works-line"></div>
                        <p className="how-it-works-text">Global, Cross-Border Access</p>
                        <div className="how-it-works-line"></div>
                        <p className="how-it-works-text">Collaborative Disruption</p>
                        <div className="how-it-works-line"></div>
                        <p className="how-it-works-text">Cost-Efficient & Transparent</p>
                        <div className="how-it-works-line"></div>
                        <p className="how-it-works-text">Multi-Asset, Multi-Institutional Opportunities</p><br /> <br />
                        <button className="global-button" onClick={() => handleOpenContactForm("demo")}>Book a demo</button>
                    </div>


                </div>
            )}
        </div>
    );
};

export default HowItWorks;